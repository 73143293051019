import * as Types from '../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventPageContentQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type EventPageContentQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, registeredEvents: { __typename?: 'GroupSessionConnection', totalCount: number }, emailCredential?: { __typename?: 'EmailCredential', email: string } | null, publicProfile?: { __typename?: 'UserPublicProfile', id: string, goal?: Types.UserPublicProfileGoal | null, profession?: { __typename?: 'Profession', id: string } | null, universityEducation?: { __typename?: 'UniversityEducation', id: string } | null, biography?: { __typename?: 'Biography', id: string } | null } | null } | null, groupSession?: { __typename?: 'GroupSession', id: string, title: string, permission: Types.GroupSessionPermission, capacity?: number | null, startTimeUTCSeconds: number, durationSeconds: number, venue?: { __typename?: 'Venue', name: string, station: string, mapURL?: string | null } | null, onlineInfo?: { __typename?: 'GroupSessionOnlineInfo', id: string, url: string, joinableTime: string } | null } | null };

export type EventInfo_EventFragment = { __typename?: 'GroupSession', title: string, capacity?: number | null, startTimeUTCSeconds: number, durationSeconds: number, venue?: { __typename?: 'Venue', name: string, station: string, mapURL?: string | null } | null };

export type EventMapAddress_VenueFragment = { __typename?: 'Venue', name: string, station: string, mapURL?: string | null };

export type EventRegistrationFormFragment = { __typename?: 'GroupSession', id: string, title: string, permission: Types.GroupSessionPermission, startTimeUTCSeconds: number, durationSeconds: number, venue?: { __typename?: 'Venue', name: string, station: string } | null, onlineInfo?: { __typename?: 'GroupSessionOnlineInfo', id: string, url: string, joinableTime: string } | null };

export type EventRegistrationForm_OnlineInfoFragment = { __typename?: 'GroupSessionOnlineInfo', id: string, url: string, joinableTime: string };

export type EventRegistrationForm_ViewerFragment = { __typename?: 'User', id: string, emailCredential?: { __typename?: 'EmailCredential', email: string } | null, publicProfile?: { __typename?: 'UserPublicProfile', id: string, goal?: Types.UserPublicProfileGoal | null, profession?: { __typename?: 'Profession', id: string } | null, universityEducation?: { __typename?: 'UniversityEducation', id: string } | null, biography?: { __typename?: 'Biography', id: string } | null } | null };

export type EventCard_GroupSessionFragment = { __typename?: 'GroupSession', id: string, title: string, startTimeUTCSeconds: number, durationSeconds: number, venue?: { __typename?: 'Venue', name: string, station: string } | null };

export type EventCardTitle_GroupSessionFragment = { __typename?: 'GroupSession', title: string, startTimeUTCSeconds: number, durationSeconds: number };

export type GetEventTitleWithModifier_GroupSessionFragment = { __typename?: 'GroupSession', title: string, startTimeUTCSeconds: number, durationSeconds: number };

export type GetEventStatus_GroupSessionFragment = { __typename?: 'GroupSession', startTimeUTCSeconds: number, durationSeconds: number };

export type EventCardTimeInfo_GroupSessionFragment = { __typename?: 'GroupSession', startTimeUTCSeconds: number, durationSeconds: number };

export type EventCardLocationInfo_VenueFragment = { __typename?: 'Venue', name: string, station: string };

export type EventPageContent_GroupSessionFragment = { __typename?: 'GroupSession', id: string, title: string, capacity?: number | null, startTimeUTCSeconds: number, durationSeconds: number, venue?: { __typename?: 'Venue', name: string, station: string, mapURL?: string | null } | null };

export type EventRegistrationFormSubmitMutationVariables = Types.Exact<{
  eventID: Types.Scalars['ID']['input'];
  channel: Types.Scalars['String']['input'];
}>;


export type EventRegistrationFormSubmitMutation = { __typename?: 'Mutation', viewerCreateGroupSessionRequest?: { __typename?: 'GroupSessionRequest', id: string } | null };

export type EventRegistrationFormDeregisterMutationVariables = Types.Exact<{
  eventID: Types.Scalars['ID']['input'];
}>;


export type EventRegistrationFormDeregisterMutation = { __typename?: 'Mutation', viewerDeregisterGroupSession: boolean };

export type EventRegistrationFormGuestSubmitMutationVariables = Types.Exact<{
  sessionID: Types.Scalars['ID']['input'];
  email: Types.Scalars['String']['input'];
  firstName: Types.Scalars['String']['input'];
  lastName: Types.Scalars['String']['input'];
  company: Types.Scalars['String']['input'];
  channel: Types.Scalars['String']['input'];
}>;


export type EventRegistrationFormGuestSubmitMutation = { __typename?: 'Mutation', guestCreateGroupSessionRequest: boolean };

export const EventCardTimeInfo_GroupSessionFragmentDoc = gql`
    fragment EventCardTimeInfo_groupSession on GroupSession {
  startTimeUTCSeconds
  durationSeconds
}
    `;
export const GetEventStatus_GroupSessionFragmentDoc = gql`
    fragment GetEventStatus_groupSession on GroupSession {
  startTimeUTCSeconds
  durationSeconds
}
    `;
export const EventCardLocationInfo_VenueFragmentDoc = gql`
    fragment EventCardLocationInfo_venue on Venue {
  name
  station
}
    `;
export const EventRegistrationForm_OnlineInfoFragmentDoc = gql`
    fragment EventRegistrationForm_onlineInfo on GroupSessionOnlineInfo {
  id
  url
  joinableTime
}
    `;
export const EventRegistrationFormFragmentDoc = gql`
    fragment EventRegistrationForm on GroupSession {
  id
  title
  permission
  ...EventCardTimeInfo_groupSession
  ...GetEventStatus_groupSession
  venue {
    ...EventCardLocationInfo_venue
  }
  onlineInfo {
    ...EventRegistrationForm_onlineInfo
  }
}
    ${EventCardTimeInfo_GroupSessionFragmentDoc}
${GetEventStatus_GroupSessionFragmentDoc}
${EventCardLocationInfo_VenueFragmentDoc}
${EventRegistrationForm_OnlineInfoFragmentDoc}`;
export const EventRegistrationForm_ViewerFragmentDoc = gql`
    fragment EventRegistrationForm_viewer on User {
  id
  emailCredential {
    email
  }
  publicProfile {
    id
    goal
    profession {
      id
    }
    universityEducation {
      id
    }
    biography {
      id
    }
  }
}
    `;
export const GetEventTitleWithModifier_GroupSessionFragmentDoc = gql`
    fragment GetEventTitleWithModifier_groupSession on GroupSession {
  title
  ...GetEventStatus_groupSession
}
    ${GetEventStatus_GroupSessionFragmentDoc}`;
export const EventCardTitle_GroupSessionFragmentDoc = gql`
    fragment EventCardTitle_groupSession on GroupSession {
  ...GetEventTitleWithModifier_groupSession
}
    ${GetEventTitleWithModifier_GroupSessionFragmentDoc}`;
export const EventCard_GroupSessionFragmentDoc = gql`
    fragment EventCard_groupSession on GroupSession {
  id
  title
  ...EventCardTitle_groupSession
  venue {
    ...EventCardLocationInfo_venue
  }
  ...EventCardTimeInfo_groupSession
}
    ${EventCardTitle_GroupSessionFragmentDoc}
${EventCardLocationInfo_VenueFragmentDoc}
${EventCardTimeInfo_GroupSessionFragmentDoc}`;
export const EventMapAddress_VenueFragmentDoc = gql`
    fragment EventMapAddress_venue on Venue {
  name
  station
  mapURL
}
    `;
export const EventInfo_EventFragmentDoc = gql`
    fragment EventInfo_event on GroupSession {
  title
  capacity
  startTimeUTCSeconds
  durationSeconds
  ...GetEventTitleWithModifier_groupSession
  venue {
    ...EventMapAddress_venue
  }
}
    ${GetEventTitleWithModifier_GroupSessionFragmentDoc}
${EventMapAddress_VenueFragmentDoc}`;
export const EventPageContent_GroupSessionFragmentDoc = gql`
    fragment EventPageContent_groupSession on GroupSession {
  id
  ...EventInfo_event
}
    ${EventInfo_EventFragmentDoc}`;
export const EventPageContentDocument = gql`
    query EventPageContent($id: ID!) {
  viewer {
    ...EventRegistrationForm_viewer
    registeredEvents(where: {id: $id}) {
      totalCount
    }
  }
  groupSession(id: $id) {
    id
    ...EventPageContent_groupSession
    ...EventRegistrationForm
  }
}
    ${EventRegistrationForm_ViewerFragmentDoc}
${EventPageContent_GroupSessionFragmentDoc}
${EventRegistrationFormFragmentDoc}`;

/**
 * __useEventPageContentQuery__
 *
 * To run a query within a React component, call `useEventPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventPageContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventPageContentQuery(baseOptions: Apollo.QueryHookOptions<EventPageContentQuery, EventPageContentQueryVariables> & ({ variables: EventPageContentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventPageContentQuery, EventPageContentQueryVariables>(EventPageContentDocument, options);
      }
export function useEventPageContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventPageContentQuery, EventPageContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventPageContentQuery, EventPageContentQueryVariables>(EventPageContentDocument, options);
        }
export function useEventPageContentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EventPageContentQuery, EventPageContentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventPageContentQuery, EventPageContentQueryVariables>(EventPageContentDocument, options);
        }
export type EventPageContentQueryHookResult = ReturnType<typeof useEventPageContentQuery>;
export type EventPageContentLazyQueryHookResult = ReturnType<typeof useEventPageContentLazyQuery>;
export type EventPageContentSuspenseQueryHookResult = ReturnType<typeof useEventPageContentSuspenseQuery>;
export type EventPageContentQueryResult = Apollo.QueryResult<EventPageContentQuery, EventPageContentQueryVariables>;
export const EventRegistrationFormSubmitDocument = gql`
    mutation EventRegistrationFormSubmit($eventID: ID!, $channel: String!) {
  viewerCreateGroupSessionRequest(sessionID: $eventID, channel: $channel) {
    id
  }
}
    `;
export type EventRegistrationFormSubmitMutationFn = Apollo.MutationFunction<EventRegistrationFormSubmitMutation, EventRegistrationFormSubmitMutationVariables>;

/**
 * __useEventRegistrationFormSubmitMutation__
 *
 * To run a mutation, you first call `useEventRegistrationFormSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventRegistrationFormSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventRegistrationFormSubmitMutation, { data, loading, error }] = useEventRegistrationFormSubmitMutation({
 *   variables: {
 *      eventID: // value for 'eventID'
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useEventRegistrationFormSubmitMutation(baseOptions?: Apollo.MutationHookOptions<EventRegistrationFormSubmitMutation, EventRegistrationFormSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EventRegistrationFormSubmitMutation, EventRegistrationFormSubmitMutationVariables>(EventRegistrationFormSubmitDocument, options);
      }
export type EventRegistrationFormSubmitMutationHookResult = ReturnType<typeof useEventRegistrationFormSubmitMutation>;
export type EventRegistrationFormSubmitMutationResult = Apollo.MutationResult<EventRegistrationFormSubmitMutation>;
export type EventRegistrationFormSubmitMutationOptions = Apollo.BaseMutationOptions<EventRegistrationFormSubmitMutation, EventRegistrationFormSubmitMutationVariables>;
export const EventRegistrationFormDeregisterDocument = gql`
    mutation EventRegistrationFormDeregister($eventID: ID!) {
  viewerDeregisterGroupSession(sessionID: $eventID)
}
    `;
export type EventRegistrationFormDeregisterMutationFn = Apollo.MutationFunction<EventRegistrationFormDeregisterMutation, EventRegistrationFormDeregisterMutationVariables>;

/**
 * __useEventRegistrationFormDeregisterMutation__
 *
 * To run a mutation, you first call `useEventRegistrationFormDeregisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventRegistrationFormDeregisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventRegistrationFormDeregisterMutation, { data, loading, error }] = useEventRegistrationFormDeregisterMutation({
 *   variables: {
 *      eventID: // value for 'eventID'
 *   },
 * });
 */
export function useEventRegistrationFormDeregisterMutation(baseOptions?: Apollo.MutationHookOptions<EventRegistrationFormDeregisterMutation, EventRegistrationFormDeregisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EventRegistrationFormDeregisterMutation, EventRegistrationFormDeregisterMutationVariables>(EventRegistrationFormDeregisterDocument, options);
      }
export type EventRegistrationFormDeregisterMutationHookResult = ReturnType<typeof useEventRegistrationFormDeregisterMutation>;
export type EventRegistrationFormDeregisterMutationResult = Apollo.MutationResult<EventRegistrationFormDeregisterMutation>;
export type EventRegistrationFormDeregisterMutationOptions = Apollo.BaseMutationOptions<EventRegistrationFormDeregisterMutation, EventRegistrationFormDeregisterMutationVariables>;
export const EventRegistrationFormGuestSubmitDocument = gql`
    mutation EventRegistrationFormGuestSubmit($sessionID: ID!, $email: String!, $firstName: String!, $lastName: String!, $company: String!, $channel: String!) {
  guestCreateGroupSessionRequest(
    email: $email
    sessionID: $sessionID
    lastName: $lastName
    firstName: $firstName
    company: $company
    channel: $channel
  )
}
    `;
export type EventRegistrationFormGuestSubmitMutationFn = Apollo.MutationFunction<EventRegistrationFormGuestSubmitMutation, EventRegistrationFormGuestSubmitMutationVariables>;

/**
 * __useEventRegistrationFormGuestSubmitMutation__
 *
 * To run a mutation, you first call `useEventRegistrationFormGuestSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventRegistrationFormGuestSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventRegistrationFormGuestSubmitMutation, { data, loading, error }] = useEventRegistrationFormGuestSubmitMutation({
 *   variables: {
 *      sessionID: // value for 'sessionID'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      company: // value for 'company'
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useEventRegistrationFormGuestSubmitMutation(baseOptions?: Apollo.MutationHookOptions<EventRegistrationFormGuestSubmitMutation, EventRegistrationFormGuestSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EventRegistrationFormGuestSubmitMutation, EventRegistrationFormGuestSubmitMutationVariables>(EventRegistrationFormGuestSubmitDocument, options);
      }
export type EventRegistrationFormGuestSubmitMutationHookResult = ReturnType<typeof useEventRegistrationFormGuestSubmitMutation>;
export type EventRegistrationFormGuestSubmitMutationResult = Apollo.MutationResult<EventRegistrationFormGuestSubmitMutation>;
export type EventRegistrationFormGuestSubmitMutationOptions = Apollo.BaseMutationOptions<EventRegistrationFormGuestSubmitMutation, EventRegistrationFormGuestSubmitMutationVariables>;