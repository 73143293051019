import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  details: [
    {
      type: 'description',
      heading: 'Event Overview',
      paragraphs: [
        {
          content:
            'alliz is a career mentorship community run by members within the community, focused on career design and development in Japan. At alliz, we focus on fostering mentorship and creating meaningful value through community-driven experiences. Our unique approach connects students, early-career professionals, and mentors from various tech related industries to share experiences, insights, and guidance, promoting mutual growth and learning. ',
        },
        {
          content:
            "Design plays a crucial role in product development, whether in a fast-moving startup or a large corporation. From shaping user experiences to driving business impact, product designers navigate complex challenges to create meaningful solutions. At alliz's past events, we've seen a growing interest from the design community in learning and mentorship opportunities. To further support this, alliz is collaborating with the Tokyo Design Community on an event titled “Design Career Tales - Journeys in Japanese Product Design”. Tokyo Design is a career hub that empowers aspiring UX/UI designers through hands-on workshops, real case studies, and portfolio development, fostering entrepreneurial thinking and deep understanding of Japan's product design landscape.",
        },
        {
          content:
            'In this special event, three product designers—spanning different generations, industries, and cultural backgrounds—will share their career stories and insights into Japan’s evolving product design landscape. From career transitions to daily challenges, gain diverse perspectives on what it truly means to be a product designer in contemporary Japan.',
        },
        {
          content:
            'This event aims to offer inspiration, guidance, and practical advice for designers navigating their careers. It’s ideal for students, junior-to-mid-level product designers, and those considering a career transition into design. Join us for an intimate evening of storytelling, learning, and networking with fellow designers and industry professionals!',
        },
      ],
    },
    {
      type: 'list',
      heading: 'Who is this for',
      items: [
        'Aspiring designers exploring careers in product design.',
        'Students & professionals seeking mentorship and career advice.',
        'Tech & design enthusiasts curious about the industry.',
        'Career changers pivoting into UX/UI or product design.',
        'Network builders looking to connect with industry peers.',
      ],
    },
    {
      type: 'description',
      heading: 'Guest & Topic Introduction',
      paragraphs: [
        {
          heading:
            'Guest Speaker＃1: Masaki Haruta (Hal), Product Design Lead, GMO Flatt Security',
          content:
            'Hal is a product design leader at GMO Flatt Security, where he drives product design, management, and the development of collaborative design systems. Prior to this role, he spent eight years at freee, where he led product design for their accounting platform and managed the global design team. His career demonstrates expertise in creating impactful products across Japanese and global markets, combining technical insight with user-centered design principles.',
        },
        {
          heading:
            'Topic #1: What One Likes, One Will Do Well: How Personal Passions Fuel Professional Growth',
          content:
            'After spending 8.5 years at freee as a product designer and manager of the global design team, Hal joined GMO Flatt Security, a cybersecurity startup. In his role as a product designer, he conducts design research and UI design while also serving as a product owner shaping product directions. He further contributes as a design engineer improving functionality and as a customer support representative handling inquiries. These diverse activities stem from his foundation of pursuing what he loves, including numerous creative projects in his personal life. In this session, Hal will share how following his passions and exploring creative endeavors outside of work have expanded his career possibilities and contributed to his professional growth, drawing from his personal experiences.',
        },
        {
          content: '',
        },
        {
          heading:
            'Guest Speaker＃2: Zhaoli Jin, Product Designer, LegalOn Technologies',
          content:
            'Meet Zhaoli JIN, a seasoned product designer with 10 years of experience. Currently Senior Product Designer at LegalOn Technology, he designs global products for international users. His design journey began at pixiv in Japan, where he spent a formative decade, progressing from graduate to Design Manager. At pixiv, he guided Japanese designers and elevated design quality. Passionate about sharing expertise, Zhaoli mentors international designers, advising on the Japanese job market. He also has 290k followers in Chinese social media.',
        },
        {
          heading:
            'Topic #2: Design: East vs. West? Speed Wins. Why "Japanese Design" and "Western Design" are Illusions',
          content:
            'Many believe design is culturally specific, like Japanese vs. Western styles. This talk argues that core design methods are actually universal and highly abstract. Startups, even with limited design resources or junior designers, can confidently use these basic frameworks. This is for startup members and early teams who want to get design right without overthinking it.',
        },
        {
          content: '',
        },
        {
          heading:
            'Guest Speaker #3: Asuka Kondo, Innovation / Design Director, D4V',
          content:
            'Asuka Kondo is a design director with extensive experience across venture capital, consulting, and advertising. Currently a Design Director at D4V  (Design for Ventures), she supports over 54 companies with sustainable and innovative design strategies. Her prior roles include spearheading design initiatives at McKinsey & Company, where she led 100+ projects and trained over 1,000 colleagues in design thinking, with a strong passion for sustainability and strategic design.',
        },
        {
          heading: 'Topic #3: Business Thinking for Designers',
          content:
            '“Business Thinking for Designers” is a practical approach that bridges the gap between design and business strategy. It empowers designers to align their creative solutions with organizational goals, driving measurable impact. By understanding business fundamentals like market dynamics, customer needs, and financial implications, designers can craft innovative solutions that are strategically effective.',
        },
      ],
    },
    {
      type: 'schedule',
      heading: 'Timetable',
      items: [
        {
          timeUTC: 1743154200,
          description: 'Doors Open',
        },
        {
          timeUTC: 1743156000,
          description: 'Opening & Introduction',
        },
        {
          timeUTC: 1743156900,
          description: 'Guest Talk 1: What One Likes, One Will Do Well',
          info: ['Masaki Haruta (Hal), Product Design Lead, GMO Flatt Security'],
        },
        {
          timeUTC: 1743157680,
          description: 'Guest Talk 2: Design: East vs. West? Speed Wins.',
          info: ['Zhaoli Jin, Product Designer, LegalOn Technologies'],
        },
        {
          timeUTC: 1743158160,
          description: 'Guest Talk 3: Business Thinking for Designers',
          info: ['Asuka Kondo, Innovation / Design Director, D4V'],
        },
        {
          timeUTC: 1743158700,
          description: 'Panel Q&A',
        },
        {
          timeUTC: 1743159600,
          description: 'Networking',
        },
        {
          timeUTC: 1743162300,
          description: 'Closing',
        },
        {
          timeUTC: 1743163200,
          description: 'Doors Close',
        },
      ],
    },
    {
      type: 'description',
      heading: 'Important Information',
      paragraphs: [
        {
          content:
            'Application: The event is free to attend and will accommodate up to 50 participants. Once your registration is confirmed, you will receive a calendar invitation. Please sign up by Wednesday, March 26, 2025.',
        },
        {
          content:
            'Language: The event will primarily be conducted in English. During networking time, conversations in both English and Japanese are welcome.',
        },
        {
          content:
            'Refreshments: Light refreshments will be provided, please manage any allergies accordingly. You are welcome to bring your own snacks and soft drinks. ',
        },
        {
          content:
            'Photography: Photos and videos may be taken during the event and could be used for promotional purposes on alliz’s website and social media. By signing up for this event, you give us permission to use these photos and videos. If you do not want to be photographed but still wish to attend, please contact alliz.',
        },
        {
          content:
            'Schedule: The event schedule is subject to change on the day.',
        },
        {
          content:
            'Contact: For any event-related inquiries, please contact alliz via email at event@alliz.jp.',
        },
      ],
    },
  ],
  tags: [],
  thumbnailURL: '/static/images/kMASOkQt47/nY9Mw8dnAK9K3vEj1lC62aJKZmq6EOL.png',
};

export default DATA;
