import {addSeconds} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';

export interface Options {
  durationSeconds: number;
  // If it's number, it needs to be in seconds UTC, if it's string, it
  // needs to be able to be parsed by Date constructor
  startTime: number | string;
}

/**
 * Returns the hour range in JST
 */
export default function formatHourRange(options: Options): string {
  const {durationSeconds, startTime} = options;
  const startTimeDate = new Date(
    typeof startTime === 'string' ? startTime : startTime * 1000,
  );
  const endTimeDate = addSeconds(startTimeDate, durationSeconds);

  const startHour = formatInTimeZone(startTimeDate, 'Asia/Tokyo', 'p');
  const endHour = formatInTimeZone(endTimeDate, 'Asia/Tokyo', 'p');

  return `${startHour} - ${endHour}`;
}
