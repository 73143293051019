import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  tags: [],
  thumbnailURL:
    '/static/images/kMASOkQt47/bYdbffEBVPVb3hdtaknKbTSZuwFSdXP6.png',
  details: [
    {
      type: 'description',
      heading: 'イベント概要',
      paragraphs: [
        {
          content:
            'allizは、コミュニティのメンバーが運営する日本におけるキャリアデザインに特化したコミュニティです。allizは今年8月にオンラインメンターシップのサービスを開始しました。この度、更にコミュニティーメンバーでの交流を深めるために、秋の自然を楽しみながらメンバー同士で交流するイベントを開催したいと思います。',
        },
        {
          content:
            '紅葉が色づく季節、心地よい秋の風を感じながら、高尾山の隣に位置する小仏城山（こぼとけしろやま）の自然を一緒に探検しませんか？本イベントは初心者向けのウォーキングイベントで、片道約1.5時間のコースを歩くことを予定しています。混雑を避け、展望が素晴らしい小仏城山を一緒に目指しましょう。途中で立ち寄る「城山茶屋」では、地元の味を楽しみながらゆっくり景色を眺め、秋の一日を満喫できます。',
        },
        {
          content:
            '参加枠に限りがあるため、本イベントはallizコミュニティのメンバー限定です。大学生、社会人、どなたでも歓迎です。分野や経験年数に関係なくご参加いただけます。先着順となりますので、まだ登録されていない方は、ぜひ登録の上、メンバープロフィールを設定してください。',
        },
      ],
    },
    {
      type: 'list',
      heading: 'こんな方におすすめ',
      items: [
        'キャリアデザインに関する目標とアドバイスを求めている方',
        'メンターシップの機会に興味がある方',
        'ネットワーキングの機会を求めている方',
        '就職・転職活動の準備をしている方',
        'allizに興味がある方',
      ],
    },
    {
      type: 'schedule',
      heading: 'タイムテーブル',
      items: [
        {
          timeUTC: 1732324860,
          description: '高尾（たかお）駅着',
        },
        {
          timeUTC: 1732325520,
          description: '高尾駅北口発：京王バス〔高尾０１ 小仏方面〕',
        },
        {
          timeUTC: 1732326780,
          description: '小仏（こぼとけ）バス停着・集合（時間厳守）',
        },
        {
          timeUTC: 1732327200,
          description: '出発：小仏バス停→景信山登山口→小仏峠→城山',
        },
        {
          timeUTC: 1732332600,
          description:
            '小仏城山の頂：休憩・昼食（小仏城山の標高は670.3ｍです。展望が良い場所に春美茶屋と城山茶屋という茶屋もあり、ゆっくり紅葉狩りを楽しめます。）',
        },
        {
          timeUTC: 1732339800,
          description: '再度出発：城山→小仏峠→景信山登山口→小仏バス停',
        },
        {
          timeUTC: 1732344600,
          description: '小仏（こぼとけ）バス停',
        },
        {
          timeUTC: 1732345200,
          description: '小仏バス停発：京王バス〔高尾０１ 高尾駅北口方面〕',
        },
        {
          timeUTC: 1732346460,
          description: '高尾駅北口到着',
        },
        {
          timeUTC: 1732347000,
          description: '解散・自由時間',
        },
      ],
    },
    {
      type: 'description',
      heading: '注意事項',
      paragraphs: [
        {
          content:
            '申し込みについて: 参加制限人数は30名です。申し込み完了次第、カレンダーインビテーションをお送りします。申し込み締め切りは2024年11月21日（木）です。イベントに関する詳細は、イベントの3日前にお知らせします。',
        },
        {
          content:
            '参加費: 参加は無料ですが、交通費や飲食費は各自での負担となります。',
        },
        {
          content:
            'コースと注意事項: 当日は小仏城山コースを歩く予定です。スケジュールや内容は当日変更になる場合があります。参加者は自身の安全や体調管理について自己責任でご参加ください。また、雨天の場合、予定が変更される可能性があります。その場合、再度参加確認のご案内を差し上げます。',
        },
        {
          content:
            '写真撮影: イベント中に写真撮影を行います。撮影された写真や動画は、allizのウェブサイトやソーシャルメディアにて宣伝目的で使用される場合があります。',
        },
        {
          content:
            'お問い合わせ: イベントに関する質問は、allizメールアドレス（event@alliz.jp）までお気軽にご連絡ください。皆様のご参加を心よりお待ちしております',
        },
      ],
    },
  ],
};

export default DATA;
