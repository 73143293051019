import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  tags: [],
  details: [
    {
      type: 'description',
      heading: 'イベント概要',
      paragraphs: [
        {
          content:
            'allizは、コミュニティのメンターが運営するプラットフォームで、日本でのキャリアデザインの開発に特化しています。”with alliz” は、IT業界で活躍するリーダーを招き、市場の変化や新技術に関する視点を共有するイベントシリーズです。トピックはデジタル製品の設計・開発、マーケティング、販売など多岐にわたります。',
        },
        {
          content:
            '初回の「Tech Talk」はエンジニアリング分野のリーダーを招き、その知識を共有していただきます。ネットワーキングの機会もありますので、一緒に学び、プロフェッショナルなつながりを築きましょう！',
        },
        {
          content:
            '大学生、社会人、どなたでも歓迎です。分野や経験年数に関係なくご参加いただけます。参加枠に限りがあるため、allizコミュニティのメンバー優先で早期登録となりますが、後ほど一般参加も受け付ける予定です。',
        },
      ],
    },
    {
      type: 'list',
      heading: 'こんな方におすすめ',
      items: [
        'IT業界での市場の変化や新技術に関する視点に興味ある方',
        'キャリアデザインに関する目標とアドバイスを求めている方',
        'メンターシップの機会に興味がある方',
        'ネットワーキングの機会を求めている方',
        '就職・転職活動の準備をしている方',
      ],
    },
    {
      type: 'schedule',
      heading: 'タイムテーブル',
      items: [
        {
          timeUTC: 1725613200,
          description: '受付開始 (受付: 18:30まで)',
        },
        {
          timeUTC: 1725615000,
          description: 'オープニング、リフレッシュメント、ネットワーキング',
        },
        {
          timeUTC: 1725616800,
          description: 'Tech Talk #1: AI and Technical Debt (英語)',
          info: ['Paul Li, Engineering Manager, Google Japan'],
        },
        {
          timeUTC: 1725618000,
          description: 'Tech Talk #2: Do the Cloud Right (英語)',
          info: [
            'Gwenn Etourneau, Staff Resident Solution Architect, Hashicorp',
          ],
        },
        {
          timeUTC: 1725619200,
          description: 'Tech Talk #3: Resilience and Leadership (英語)',
          info: ['Snehal Shinde, Director, Product Engineering, Mercari'],
        },
        {
          timeUTC: 1725620400,
          description: 'ネットワーキング',
        },
        {
          timeUTC: 1725623100,
          description: 'クロージング',
        },
        {
          timeUTC: 1725624000,
          description: '閉会',
        },
      ],
    },
    {
      type: 'list',
      heading: '申し込みについて',
      items: [
        '募集人数：60名',
        '参加費用：参加無料',
        '言語：イベントは日本語で行われます。一部のコンテンツは英語で行われます。',
        '申し込み開始：allizのメンバーの申し込みは2024年8月13日 (土)より開始、一般申し込み:2024年8月22日 (火)より。',
        '申し込み締切：2024年9月5日 (木)',
        'ウェイティングリスト：席数に限りがありますので、参加者が定員の60名に達した場合、ウェイティングリストへ追加させていただきます。',
        'キャンセル：やむを得ず欠席される場合は、allizまでご連絡ください。alliz メンバーの方は、アプリ内で直接キャンセルが可能です。',
      ],
    },
    {
      type: 'list',
      heading: '注意事項',
      items: [
        '飲み物とスナック：会場には飲み物とスナックが用意されますが、アレルギーのある方はご自身にてご配慮いただきますようお願いします。',
        'イベントに関しての連絡はGoogle for Startupにしないようお願いします。イベントに関しての質問は、allizの連絡先までお願いします。',
        '主催者allizの連絡先 : event@alliz.jp',
      ],
    },
  ],
  thumbnailURL:
    '/static/images/kMASOkQt47/Gw7hhLFlFk6Gi255eZm8AKap8cQS4Y7z.png',
};

export default DATA;
