import {GetEventStatus_GroupSessionFragment} from './__generated__/Event.graphql';

import {addSeconds, isAfter} from 'date-fns';

export type EventStatus = 'pending' | 'in-progress' | 'completed';

export default function getEventStatus(
  groupSession: GetEventStatus_GroupSessionFragment,
): EventStatus {
  const now = new Date();
  const startTime = new Date(groupSession.startTimeUTCSeconds * 1000);
  const endTime = addSeconds(startTime, groupSession.durationSeconds);

  if (isAfter(now, endTime)) {
    return 'completed';
  } else if (isAfter(now, startTime)) {
    return 'in-progress';
  }
  return 'pending';
}
