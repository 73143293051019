import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  tags: [],
  details: [
    {
      type: 'description',
      heading: 'イベント概要',
      paragraphs: [
        {
          content:
            'allizは、コミュニティのメンターが運営するプラットフォームで、日本でのキャリアデザインの開発に特化しています。7月にオンラインメンタープログラムのサービスを開始するにあたり、コミュニティのメンバーが集まり、学びを共有し、交流を通じてその成果を祝う機会を設けたいと思います。',
        },
        {
          content:
            '梅雨が始まり、なかなか外出しづらいですよね？また、仕事や勉強で忙しく、運動不足になっていませんか？暑くなる前に一緒に集まりませんか？7月6日に美しい奥多摩の自然を探検しましょう。初心者向けのウォーキングイベントで、徒歩2時間のふれあい森林浴コースを予定しています。歩きながら、おしゃべりして、一緒に楽しみましょう。',
        },
        {
          content:
            '参加枠に限りがあるため、こちらのイベントはallizコミュニティのメンバー限定です。大学生、社会人、どなたでも歓迎です。分野や経験年数に関係なくご参加いただけます。先着順となりますので、まだ登録されていない方は、ぜひ登録の上、メンバープロフィールを設定してください。',
        },
      ],
    },

    {
      type: 'list',
      heading: 'こんな方におすすめ',
      items: [
        'キャリアデザインに関する目標とアドバイスを求めている方',
        'メンターシップの機会に興味がある方',
        'ネットワーキングの機会を求めている方',
        '就職・転職活動の準備をしている方',
        'allizに興味がある方',
      ],
    },

    {
      type: 'schedule',
      heading: 'タイムテーブル',
      items: [
        {
          timeUTC: 1720221840,
          description: '奥多摩駅前集合（時間厳守）',
        },
        {
          timeUTC: 1720223100,
          description: '出発',
        },
        {
          timeUTC: 1720230300,
          description: '休憩・昼食',
        },
        {
          timeUTC: 1720236600,
          description: '再度出発',
        },
        {
          timeUTC: 1720241700,
          description: '奥多摩駅到着',
        },
        {
          timeUTC: 1720242000,
          description: '解散・自由時間',
        },
      ],
    },

    {
      type: 'description',
      paragraphs: [
        {
          content:
            '奥多摩・ふれあい森林浴コースを歩きましょう。コースの詳細はこちらです： https://www.yamareco.com/modules/yamareco/detail-6856966.html',
        },
        {
          content: '当日の時間や予定は変更される場合があります。',
        },
      ],
    },

    {
      type: 'list',
      heading: '注意事項',
      items: [
        'イベントの準備詳細は、イベントの3日前にお知らせします。雨天の場合、計画が変更される可能性がありますので、その際は再度参加確認をお願いすることがあります。',
        'ご家族、ペット同伴可です。事前にallizまでお知らせください。',
        '参加者は自身の安全やその他の事項について自己責任でご対応ください。',
        '募集人数：30名',
        '参加費用：参加無料（交通費や飲食費は各自ご負担ください）',
        '申し込み締切：2024年7月4日（木）',
        '主催者allizの連絡先：event@alliz.jp',
      ],
    },
  ],
  thumbnailURL: '/static/images/kMASOkQt47/nY9Mw8dnAK9K3vEj1lC62aJKZmq6EOI.png',
};

export default DATA;
