import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  tags: [],
  details: [
    {
      type: 'description',
      heading: 'イベント概要',
      paragraphs: [
        {
          content:
            'allizは、コミュニティのメンターが運営するプラットフォームで、日本でのキャリアデザインの開発に特化しています。このトークシリーズでは、個人のキャリア選択に焦点を当てます。様々な経験と国際的なバックグラウンドを持つリーディングIT企業のゲストをお招きし、彼らの知識を共有していただきます。',
        },
        {
          content:
            '昨今、日本以外の国に活躍の場を求める人が増えています。初回のトーク・セッションでは、日本および海外、特にシンガポールでのキャリア選択に焦点を当てます。国内外で働く機会を求める場合に、いつ、どのようなキャリアの決定を行ったかについて貴重なお話しを聞いていきます。オープンに、好奇心を持って、セッションにご参加ください！',
        },
      ],
    },

    {
      type: 'list',
      heading: 'こんな方におすすめ',
      items: [
        'キャリアデザインに関する目標とアドバイスを求めている方',
        'メンターシップの機会に興味がある方',
        'ネットワーキングの機会を求めている方',
        '就職・転職活動の準備をしている方',
        'allizに興味がある方',
      ],
    },

    {
      type: 'description',
      heading: '登壇者紹介',
      paragraphs: [
        {
          heading: 'ゲスト＃１：大手Eコマース企業 安藤 麻美 氏',
          content:
            'Manager of HR Business Partner & Talent Management - 広告系システムのプロダクト担当者から、コロナを経て人事へ転身。社会人経験の半分以上をシンガポールで過ごし2023年に帰国。現在は1000人規模の開発部門のHRBPに加えてタレントマネジメント領域を担当。',
        },
        {
          heading:
            'ゲスト＃２：米トップクリエイティブ・ソフトウェア企業 大木 慧 氏',
          content:
            'GTM Business Manager - 日系製造業、半導体商社で法人営業を担当した後、ドイツに渡りスタートアップのデジタルマーケティング担当として従事。その後シンガポールにわたり米IT企業の日本法人営業担当を経験し、2022年より現職。',
        },
        {
          heading: 'ゲスト＃３：米トップテクノロジー企業 武石 俊一 氏',
          content:
            'Marketing - 日系のWebサービス会社で６年、その後、出向扱いでシンガポール子会社で５年勤務。帰国したのち、転職して外資系コンピューター・デバイス企業のマーケティング系部署で勤務中。',
        },
        {
          heading: 'モデレーター : 米トップフィンテック企業 鈴木 ひかる 氏',
          content:
            'Program Manager - アメリカのIT企業でコンプライアンス・リスク分野での経験があり。2022年より現職米国IT企業でリスク分野でプログラムマネージャーとして勤務。',
        },
      ],
    },

    {
      type: 'schedule',
      heading: 'タイムテーブル',
      items: [
        {
          timeUTC: 1717310700,
          description: 'オンライン入場開始',
        },
        {
          timeUTC: 1717311600,
          description: 'オープニング ',
        },
        {
          timeUTC: 1717311900,
          description: 'パネルディスカッション',
        },
        {
          timeUTC: 1717315200,
          description: 'ブレイクアウトルーム- パネリストとのQ&A',
        },
        {
          timeUTC: 1717316100,
          description: 'グループアクティビティ',
        },
        {
          timeUTC: 1717317900,
          description: 'クロージング',
        },
        {
          timeUTC: 1717318800,
          description: '閉会',
        },
      ],
    },

    {
      type: 'list',
      heading: '注意事項',
      items: [
        'イベントは日本語で行われます。',
        'イベント前にミーティングリンクを共有します。',
        'セッションで議論される内容は個人的なものであり、会社や大学を代表するものではありません。',
        'イベント中に機密情報を共有しないでください。また、イベントで議論された内容を一般に公開しないでください。',
        'イベント前後、ゲストに質問がある場合やメンターシップを希望する場合は、まずallizにご連絡ください。',
        '申し込み締切: 2024年6月1日 16時まで',
        '主催者allizの連絡先：event@alliz.jp',
      ],
    },
  ],
  thumbnailURL:
    '/static/images/kMASOkQt47/A0S3qmXozWc556HDRUnTzGxNVogo0d9F.png',
};

export default DATA;
