import type {ExtraInfo} from './data/types';

import e34359738368 from './data/e34359738368';
import e34359738369 from './data/e34359738369';
import e34359738370 from './data/e34359738370';
import e34359738371 from './data/e34359738371';
import e34359738372 from './data/e34359738372';
import e34359738373 from './data/e34359738373';
import e34359738374 from './data/e34359738374';
import e34359738375 from './data/e34359738375';
import e34359738376 from './data/e34359738376';
import e34359738377 from './data/e34359738377';
import e34359738378 from './data/e34359738378';
import e34359738379 from './data/e34359738379';
import e34359738380 from './data/e34359738380';

const EXTRA_INFO: Record<string, ExtraInfo> = {
  34359738368: e34359738368,
  34359738369: e34359738369,
  34359738370: e34359738370,
  34359738371: e34359738371,
  34359738372: e34359738372,
  34359738373: e34359738373,
  34359738374: e34359738374,
  34359738375: e34359738375,
  34359738376: e34359738376,
  34359738377: e34359738377,
  34359738378: e34359738378,
  34359738379: e34359738379,
  34359738380: e34359738380,
};

export default EXTRA_INFO;
