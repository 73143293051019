import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  details: [
    {
      type: 'description',
      heading: 'Event Overview',
      paragraphs: [
        {
          content:
            'alliz is a career mentorship community led by its members, dedicated to career design and development in Japan. We foster mentorship and meaningful connections through community-driven activities, bringing together students, early-career professionals, and mentors from tech-related industries. By sharing experiences, insights, and guidance, we promote mutual growth and learning.',
        },
        {
          content:
            'Campus Talk is an initiative that brings experienced mentors from various industries to university campuses, helping students explore career paths early on. This series empowers students to take charge of their professional development through interactive discussions and mentorship guidance.',
        },
        {
          content:
            'Our upcoming event, "Navigating the Tech Landscape in Japan," is the second in the Campus Talk series, featuring tech mentors sharing their career journeys, strategies for career-building, and tips on resilience and confidence. Students will gain practical insights into navigating tech careers, balancing studies with career preparation, and crafting standout portfolios.',
        },
        {
          content:
            'This event is exclusive to University students at Temple University Japan Campus in collaboration with the Computer Science department. Though this event is centered around computer science students, the talks are designed to be accessible to all. No in-depth technical knowledge is required, and the content will be relevant for anyone interested in gaining career advice, learning about professional growth, and exploring pathways in tech or beyond.',
        },
      ],
    },
    {
      type: 'list',
      heading: 'Who is this for',
      items: [
        'Computer Science major/minor students at any level',
        'Students seeking guidance on career design and growth',
        'Students curious about tech and non-tech career paths',
        'Students interested in mentorship opportunities and real-world insights',
        'Students interested in networking with professionals',
      ],
    },
    {
      type: 'description',
      heading: 'Guest & Topic Introduction',
      paragraphs: [
        {
          heading: 'Snehal Shinde, Director, Product Engineering, Mercari',
          content:
            'Snehal is the Engineering Director at Mercari, where she leads cross-functional collaborations and drives a culture of innovation. She guides product engineering teams to develop effective solutions. Snehal enjoys mentoring and promoting growth.',
        },
        {
          heading:
            "Navigating the Tech Landscape in Japan: A Foreigner's Journey and Insights",
          content:
            "Snehal will discuss the unique challenges and rewards she has encountered, from navigating cultural differences to overcoming language barriers. Drawing on her experiences, she’ll provide insights into finding job opportunities, building a network, and adapting to the workplace culture here. Whether you're considering a career in tech abroad or are simply curious about life in Japan, Snehal hopes to offer valuable advice and inspiration for your own journey. Join her as we explore the vibrant tech landscape of Japan together!",
        },
      ],
    },
    {
      type: 'schedule',
      heading: 'Timetable',
      items: [
        {
          timeUTC: 1742950800,
          description: 'Opening',
        },
        {
          timeUTC: 1742951100,
          description:
            'Career Story: From Computer Science Graduate to Startup Founder in Japan',
          info: ['Congqin Chen, Founder, alliz'],
        },
        {
          timeUTC: 1742951700,
          description:
            "Guest Talk: Navigating the Tech Landscape in Japan - A Foreigner's Journey and Insights",
          info: ['Snehal Shinde, Director, Product Engineering, Mercari'],
        },
        {
          timeUTC: 1742953500,
          description: 'Discussion with Students',
        },
        {
          timeUTC: 1742954100,
          description: 'Group Photo & Closing',
        },
        {
          timeUTC: 1742954400,
          description: 'Event End',
        },
      ],
    },
    {
      type: 'description',
      heading: 'Important Information',
      paragraphs: [
        {
          content:
            'Fee and registration: This event will be held in English, free for participation. Total participants are 35. It is exclusive to University students in Temple University Japan Campus, hence not open for public registration.',
        },
        {
          content:
            'Photography: Event photos and videos will be taken and may be used for promotional purposes on alliz’s website, social media, and event announcements, with participants’ permission.',
        },
        {
          content:
            'Schedule: Within the event duration, the event schedule may change on the event day.',
        },
        {
          content:
            'Contact: For any event-related inquiries, please contact alliz at event@alliz.jp .',
        },
      ],
    },
  ],
  tags: [],
  thumbnailURL:
    '/static/images/kMASOkQt47/A0S3qmXozWc556HDRUnTzGxNVogo0d9d.png',
};

export default DATA;
