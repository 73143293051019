import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  details: [
    {
      type: 'description',
      heading: 'Event Overview',
      paragraphs: [
        {
          content:
            'alliz is a career mentorship community led by its members, dedicated to career design and development in Japan. We foster mentorship and meaningful connections through community-driven activities, bringing together students, early-career professionals, and mentors from tech-related industries. By sharing experiences, insights, and guidance, we promote mutual growth and learning.',
        },
        {
          content:
            'April 13 marks one year since our first career event at the Adobe office, and we’re excited to celebrate this milestone with another special event at the same venue! This year’s theme, “Mentorship for All,” reflects our commitment to making mentorship open, inclusive, and accessible. We’re bringing together mentors from diverse tech fields to lead small group discussions and share their career stories, offering guidance and inspiration to participants. To enhance the learning experience, we’re also welcoming innovative businesses that share a passion for supporting students to broaden perspectives, foster collaboration, and potentially open new career opportunities.',
        },
        {
          content:
            'This event is open to students and professionals of all fields and experience levels. It’s ideal for students seeking career guidance and professionals interested in mentoring and contributing to community development. As a special treat, Shotaro Matsuo, owner and head chef of Purna Shokudo, will prepare handmade traditional Indian sweet Laddu for the networking session, adding a touch of joy and cultural flavor to the event. Join us and be part of this growing community!',
        },
      ],
    },
    {
      type: 'list',
      heading: 'Who is this for',
      items: [
        'Students and professionals seeking guidance on career design and growth',
        'Students and professionals interested in mentorship opportunities',
        'Students and professionals looking for networking opportunities',
        'Professionals and students passionate about community services',
        'University faculty and student-support organizations seeking collaboration',
      ],
    },
    {
      type: 'description',
      heading: 'Guest Speaker and Topic',
      paragraphs: [
        {
          heading: 'Takao Watanabe, Chief Operation Officer, Equmenopolis',
          content:
            'After engaging in robotics research at university, Takao has been involved in new product development and product/project management in the areas of robotics, connected cars, and AI at a foreign-affiliated manufacturing company. With his experience in patent prosecution and joint research between domestic and international universities, research institutes, and companies, he will be a bridge between research and development at Equmenopolis, executing all tasks to deliver cutting-edge technology as products to society.',
        },
        {
          content:
            'Equmenopolis is a startup focused on the research and development of conversational AI, founded by the Perceptual Information Systems Laboratory at Waseda University. Believing in enhancing humanity through innovative "Deep Human Technology", Equmenopolis aims to build a society where humans and AI collaborate and grow together.',
        },
        {
          content:
            'At this event, Takao will introduce LANGX, an innovative English language learning service developed by Equmenopolis. LANGX revolutionizes language learning with highly interactive conversational AI, enabling learners to engage in real-time conversations and improve their speaking skills naturally and effectively.',
        },
      ],
    },
    {
      type: 'description',
      heading: 'Featured Mentors',
      paragraphs: [
        {
          heading: '',
          content:
            '(In alphabetical order)',
        },
        {
          heading: 'Gwenn Etourneau, Staff Resident Architect, HashiCorp',
          content:
            'Gwenn Etourneau is currently a Staff Resident Architect at HashiCorp (now part of IBM). Based in Japan, he has worked for two decades as an Architect and Software Engineer, specializing in Cloud and Cloud Native technologies at companies like Woven by Toyota, Pivotal, and Rakuten. Throughout his career, Gwenn has mentored teams and engineers, focusing on technical leadership and fostering innovation. Outside of work, he is passionate about continuous learning and staying up-to-date with the latest trends in cloud technologies, always eager to share knowledge and contribute to the community.',
        },
        {
          heading: 'Hiroko Yano, Senior User Experience Researcher, Google Japan',
          content:
            'Hiroko Yano is a Senior User Experience Researcher (UXR) at Google. By translating user needs and product opportunities into actionable research insights, she has informed the strategy and design of various products and services (e.g., games, productivity applications, operating systems, food delivery services, and search engines) throughout her career in the US and Japan. Her passion lies in providing insights that guide product teams to develop innovative solutions for user problems, fostering product growth through a user-centric approach. Her expertise lies in crafting inclusive designs that bridge cultural, educational, and accessibility gaps for a diverse user base. She empowers individuals from diverse backgrounds through mentorship, fostering their career development, work-life balance, and passion identification.',
        },
        {
          heading: 'Kumiko Komatsu, Senior Manager, ICX Sales, Adobe Japan',
          content:
            'Kumiko currently serves as the Senior Manager of Inside Sales at Adobe K.K. with over 20 years of experience in Direct and Channel Sales, Operations Management, and Program/ Project Management within the IT industry. She is deeply passionate about mentoring early to mid-career professionals, helping them discover their potential and achieve career growth. Through mentorship, she shares experiences, insights, and wisdom. At this event, Kumiko looks forward to engaging in meaningful discussions and supporting attendees in guiding them toward their career goals.',
        },
        {
          heading: 'Lijun Liu, Data & AI Specialist, Microsoft Japan',
          content:
            'Lijun currently works as a Solution Specialist in the Data & AI Area at Microsoft Japan. She has successfully engaged in numerous projects, demonstrating strong skills in client communication and solving complex technical challenges, earning high recognition for her contributions. Leveraging her MBA education, she consistently integrates business perspectives into technology, effectively bridging technical expertise and strategic business thinking. Additionally, Lijun proactively invests in self-development and continuous learning throughout her career. Drawing upon these experiences, she excels in mentoring new joiners, helping them grow autonomously, and enabling them to contribute effectively to society.',
        },
        {
          heading: 'Neil Yuki, Chief Technology Officer, N.E.W Verse',
          content:
            'Neil is a technology strategist specializing in data and AI solutions and the founder of data platforms for Rakuten, LINE, and Indeed. He began his career tackling complex challenges with flexible thinking in dynamic environments. He leads initiatives that align market needs with cutting-edge technologies by collaborating with engineers, product managers, and designers. His work in enhancing customer support systems and developing high-precision translation solutions using large language models has significantly boosted service efficiency and global competitiveness. Neil remains passionate about merging user-centric design with innovative technology to drive business growth.',
        },
        {
          heading: 'Paul Li, Engineering Manager, Google Japan',
          content:
            'Paul is a software engineer with 14 years of experience mainly in search engines and advertising technologies. A Peking University graduate, Paul has been in Japan since 2011, with previous roles at Rakuten and Indeed. Over the last 6 years, he has held management roles. His expertise includes internet software development, distributed systems, architecture, AI/ML/LLM, software testing, people management, and career mentoring.',
        },
      ],
    },
    {
      type: 'schedule',
      heading: 'Timetable',
      items: [
        {
          timeUTC: 1744518600,
          description: 'Doors Open',
        },
        {
          timeUTC: 1744520400,
          description: 'Opening',
        },
        {
          timeUTC: 1744521300,
          description: 'Guest Presentation: “LANGX: We help people learn to speak languages like English using highly interactive conversational AI.”',
          info: ['Takao Watanabe, Chief Operation Officer, Equmenopolis'],
        },
        {
          timeUTC: 1744522200,
          description: 'Break',
        },
        {
          timeUTC: 1744523100,
          description: 'Small Group Discussions with Mentors',
          info: ['Tech Career Mixer・Working in Tech'],
        },
        {
          timeUTC: 1744526700,
          description: 'Group Photo & Networking',
        },
        {
          timeUTC: 1744530300,
          description: 'Closing',
        },
        {
          timeUTC: 1744531200,
          description: 'Doors Close',
        },
      ],
    },
    {
      type: 'description',
      heading: 'Important Information',
      paragraphs: [
        {
          content:
            'Application: The event is free to attend and will accommodate up to 50 participants. Once your registration is confirmed, you will receive a calendar invitation. Please sign up by Thursday, April 10, 2025.',
        },
        {
          content:
            'Language: The event will primarily be conducted in English. During networking time, conversations in both English and Japanese are welcome.',
        },
        {
          content:
            'Refreshments: Light refreshments will be provided, please manage any allergies accordingly. You are welcome to bring your own snacks and soft drinks. ',
        },
        {
          content:
            'Photography: Photos and videos may be taken during the event and could be used for promotional purposes on alliz’s website and social media. By signing up for this event, you permit us to use these photos and videos. If you do not want to be photographed but still wish to attend, please contact alliz.',
        },
        {
          content:
            'Schedule: The event schedule is subject to change on the day.',
        },
        {
          content:
            'Contact: For any event-related inquiries, please contact alliz via email at event@alliz.jp.',
        },
      ],
    },
    {
      type: 'description',
      heading: 'Sponsorship',
      paragraphs: [
        {
          content: 'Event Space Sponsor: Adobe Japan',
        },
        {
          content:
            'Food Sponsor: Purna Shokudo, a dining space dedicated to serving traditional Indian vegetarian cuisine. Rooted in the Indian cultural concept of "sharing meals," it operates entirely on a donation basis, driven by the belief that everyone deserves a thoughtfully prepared, nourishing meal. The restaurant values the freshness and rich flavors of dishes made exclusively with seasonal vegetables.',
        },
      ],
    },
  ],
  tags: [],
  thumbnailURL:
    '/static/images/kMASOkQt47/weBBN9bXTc2K4DTNKrx80ZYojKiJA0Dv.png',
};

export default DATA;
