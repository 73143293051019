import {GetEventTitleWithModifier_GroupSessionFragment} from './__generated__/Event.graphql';
import getEventStatus from './getEventStatus';

import {useTranslation} from 'react-i18next';

export default function EventTitleWithModifier(props: {
  groupSession: GetEventTitleWithModifier_GroupSessionFragment;
}): string {
  const {groupSession} = props;
  const {t} = useTranslation();
  const status = getEventStatus(groupSession);

  switch (status) {
    case 'completed':
      return t('components.event-card.completed') + ' ' + groupSession.title;
    case 'in-progress':
      return t('components.event-card.in-progress') + ' ' + groupSession.title;
    case 'pending':
      return groupSession.title;
  }
}
