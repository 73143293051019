import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  details: [
    {
      type: 'description',
      heading: 'Event Overview',
      paragraphs: [
        {
          content:
            'alliz is a career mentorship community run by mentors within the community, focused on career design and development in Japan. "with alliz: Campus Talk" is an initiative designed to bring experienced mentors from various industries to university campuses, creating opportunities for students to explore career paths early on. This series focuses on empowering students to take charge of their professional development, encouraging them to actively design their future careers through interactive discussions and expert guidance.',
        },
        {
          content:
            'The upcoming event, themed "Crafting a Career with Intention," alliz has invited a diverse group of mentors with extensive experience in the tech and business world. They will share their journeys, explore tech trends and thinking, essential skills for industry roles, strategies for career-building strategies, and tips for building resilience and confidence. Students will gain practical insights on navigating tech careers, balancing studies with career prep, and crafting standout portfolios.',
        },
        {
          content:
            'This event is exclusive to University students in Temple University, Japan Campus in collaboration with the Computer Science department. Though this event is centered around computer science students, the talks are designed to be accessible to all. No in-depth technical knowledge is required, and the content will be relevant for anyone interested in gaining career advice, learning about professional growth, and exploring pathways in tech or beyond.',
        },
      ],
    },
    {
      type: 'list',
      heading: 'Who is this for',
      items: [
        'Computer Science major/minor students at any level',
        'Students seeking guidance on career design and growth',
        'Students interested in proactive career planning',
        'Students looking for mentorship opportunities and real-world insights',
        'Students curious about tech and non-tech career paths',
      ],
    },
    {
      type: 'description',
      heading: 'Guest Introduction',
      paragraphs: [
        {
          heading: 'Guest Speaker＃1: Junko Fujiwara, Staff Engineer, GitHub',
          content:
            'Junko is a Staff Engineer providing technical guidance to users of development platforms on GitHub. With extensive experience as an Architect at a data visualization software company and a Software Engineering Manager at Microsoft, she brings a deep understanding of the field. Junko is dedicated to building strong connections with engineers worldwide, leveraging her expertise to support and empower developers globally.',
        },
        {
          heading:
            'Guest Speaker＃2: Xiaowei Zhou, Staff Software Engineer, Indeed Technologies Japan',
          content:
            'Xiaowei is Staff Software Engineer at Indeed Technologies Japan, holding a Master’s degree in Software Engineering from the Chinese Academy of Sciences. With a career that began at Rakuten, Xiaowei transitioned to Indeed in 2016. Xiaowei has extensive experience in both frontend and backend development for high-traffic websites, large-scale data processing, search engines, and mobile apps.',
        },
        {
          heading:
            'Guest Speaker＃3: Snehal Shinde, Director, Product Engineering, Mercari',
          content:
            'Snehal is the Engineering Director at Mercari, where she leads cross-functional collaborations and drives a culture of innovation. She guides product engineering teams to develop effective solutions. Snehal enjoys mentoring and promoting growth.',
        },
        {
          heading:
            'Guest Speaker＃4: Yuchen Liu, Software Development Engineer, Global EC Company',
          content:
            'Yuchen is a software development engineer at a leading global e-commerce company, specializing in improving data quality for product search engines. Yuchen studied at Tokyo Institute of Technology, and holds a master degree of Engineering at the Department of Information and Communication Engineering. During his studies, Yuchen also gained internship experiences at major tech companies, including Indeed and Amazon.',
        },
      ],
    },
    {
      type: 'description',
      heading: 'Topic Introduction',
      paragraphs: [
        {
          heading:
            'Topic #1: Exploring the Future of Technology: How Innovations Grow and Change',
          content:
            "Technology is always changing, influencing the way we live and the world around us. In this session, we'll take a look at Gartner's Hype Cycle, a chart that helps us see how new ideas and technologies grow from early excitement to everyday use. We'll think about how technology has changed in the past and what might happen in the future. By understanding these patterns, students will learn how to think about the future of technology and how it might affect their lives, studies, and careers in the years to come.",
        },
        {
          heading: 'Topic #2: Unveiling Industry-Level Software Development',
          content:
            'In the industry, software development often involves many people collaborating on the same code. These members may be on different teams and span multiple locations and time zones. And we want the software we build to have as few bugs as possible. This situation presents challenges to how we develop software and raises different concerns compared to academic projects. Let’s take a closer look at industry-scale software development and discuss what it means to you as a student.',
        },
        {
          heading:
            'Topic #3: Transform Challenges into Triumphs: Your Journey Awaits! ',
          content:
            'Join us as we explore the transformative challenges that students face during their university years and the exhilarating leap into their first jobs. This engaging discussion will emphasize the power of developing leadership and technical skills early, enabling you to navigate your academic and professional journey with confidence. We will also delve into the art of building remarkable portfolios that reflect your unique talents. Moreover, we’ll share uplifting strategies to overcome self-doubt and foster resilience, inspiring you to thrive in every step of your path.',
        },
        {
          heading: 'Topic #4: Landing a Tech Job While You’re at School',
          content:
            'Join us to explore ways to secure tech internships while managing studies. Discover effective strategies for balancing coursework and career preparation, building essential skills, and tackling interviews confidently—even without advanced experience. Learn how internships provide hands-on knowledge and shape career direction, ideal for students curious about tech roles and industry exposure!',
        },
      ],
    },
    {
      type: 'schedule',
      heading: 'Timetable',
      items: [
        {
          timeUTC: 1732496400,
          description: 'Opening and alliz introduction',
        },
        {
          timeUTC: 1732496700,
          description:
            'Guest Talk #1: Exploring the Future of Technology: How Innovations Grow and Change',
          info: ['Junko Fujiwara, Staff Engineer, GitHub'],
        },
        {
          timeUTC: 1732497300,
          description:
            'Guest Talk #2: Unveiling Industry-Level Software Development',
          info: [
            'Xiaowei Zhou, Staff Software Engineer, Indeed Technologies Japan',
          ],
        },
        {
          timeUTC: 1732497900,
          description:
            'Guest Talk #3: Transform Challenges into Triumphs: Your Journey Awaits!',
          info: ['Snehal Shinde, Director, Product Engineering, Mercari'],
        },
        {
          timeUTC: 1732498500,
          description:
            'Guest Talk #4: Landing a Tech Job While You’re at School',
          info: [
            'Yuchen Liu, Software Development Engineer, Global EC company',
          ],
        },
        {
          timeUTC: 1732499100,
          description: 'Discussion with students',
        },
        {
          timeUTC: 1732499700,
          description: 'Closing',
        },
        {
          timeUTC: 1732500000,
          description: 'Event Close',
        },
      ],
    },
    {
      type: 'description',
      heading: 'Important Information',
      paragraphs: [
        {
          content:
            'Fee and registration: This event will be held in English, free for participation. Total participants are 35. It is exclusive to University students in Temple University, Japan Campus, hence not open for public registration. ',
        },
        {
          content:
            'Photography: Event photos and videos will be taken and may be used for promotional purposes on alliz’s website, social media, and event announcements, with participants’ permission.',
        },
        {
          content:
            'Schedule: Within the event duration, event schedule that may change on the event day.',
        },
        {
          content:
            'Contact: For any event-related inquiries, please contact alliz at event@alliz.jp .',
        },
      ],
    },
  ],
  tags: [],
  thumbnailURL:
    '/static/images/kMASOkQt47/1CDR5nmNdsdoLh1waJLLeccyCcVlPK9M.png',
};

export default DATA;
